import React from 'react'
import './dreamTeam.css'
import Team from './Team'
import { teamInfos } from './teamInfo'

function DreamTeam () {
  return (
    <div className='dream-team'>
      <div className='dream-team-header' />
      <div className='dream-team-container'>
        {teamInfos.map(({ name, image, bio, title }, index) => (
          <Team name={name} image={image} bio={bio} title={title} />
        ))}
      </div>
    </div>
  )
}

export default DreamTeam
