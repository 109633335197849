import React, { useState, useRef } from 'react'
import './meeting.css'
import emailjs from 'emailjs-com'
import VideocamIcon from '@mui/icons-material/Videocam'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

function Meeting () {
  const [sendStatus, setSendStatus] = useState(false)
  const [error, setError] = useState(false)
  const [showMeetingForm, setShowMeetingForm] = useState(false)

  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_dkqg9c6',
        'template_wwo08ad',
        form.current,
        'dFSzfOLyvGNa9_KBw'
      )
      .then(
        result => {
          console.log(result.text)
          setSendStatus(true)
        },
        error => {
          console.log(error.text)
          setError(true)
        }
      )
  }

  return (
    <div className='meeting'>
      {showMeetingForm ? (
        <div className='meeting-container'>
          <p>
            Please fill out the form and we will send the zoom link. Thank you!{' '}
          </p>

          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type='text' name='full_name' required />
            <label>Email</label>
            <input type='email' name='user_email' required />
            <label>Phone Number</label>
            <input type='number' name='phone_number' required />

            <input className='submit_button' type='submit' value='Send' />
            {sendStatus ? (
              <h3 className='success-sent'>Sent!</h3>
            ) : error ? (
              <h3 className='error-sent'>Error! Please contact us!</h3>
            ) : null}
          </form>
          <button onClick={() => setShowMeetingForm(false)}>
            <ArrowBackIcon />
            Back
          </button>
        </div>
      ) : (
        <div className='meet-container'>
          <div className="top-top-container">
            <h2><strong>Our Real Talk Bible Study Group consists of people who meet to read the Bible together, write in their journals using the SOAP method, and share their devotions. 
            We meet virtually via zoom on Sunday mourning from 10:00 am until 11:00 a.m. and on Wednesday from 7:00 p.m. until 8:00 pm.</strong></h2>
            <br />
            <h2>SOAP Method:</h2>
            <br />
            <h2>SCRIPTURE</h2>
            <p>
              Take time reading and allow God to speak to you. When you are done, look for a verse that particularly spoke to you that day, and write it in your journal.
            </p>            
            <br />
            <h2>OBSERVATION</h2>
            <p>
              What do you think God is saying to you in this scripture? Ask the Holy Spirit to teach you and reveal Jesus to you. Paraphrase and write this Scripture down in your own words in your journal.
            </p>
            <br />
            <h2>APPLICATION</h2>
            <p>
              Personalize what you have read, by asking yourself how it applies to your life right now. Perhaps it is instruction, encouragement, revelation of a new promise, or corrections for a particular area of your life. Write how this Scripture can apply to you today.
            </p>
            <br />
            <h2>PRAYER</h2>
            <p> 
              This can be as simple as asking God to help you use this Scripture, or it may be a greater insight on what He may be revealing to you. Remember, prayer is a two-way conversation, so be sure to listen to what God has to say!
            </p>
            <br />
            <h2>Interested in Joining? Here is what you need to do</h2>
            <p>If you would like to be a part of this group, please provide your email address.  We will use it to contact you. You may send your request to join the group via email to nolmhousechurch2022@gmail.com . We will meet with each person individually on Zoom before bringing them into the Bible studies.
            Meeting ahead of time will give you a chance to meet us, ask any questions you might have, and we will also share more details about the Bible studies. In addition, we want to make sure that you are smoothly able to use Zoom.</p>
          </div>
          <br />
          <div className='top-container'>
            <h1>Zoom Small Group Meeting</h1>
            <p>
              Join us on Zoom for a Bible Study. To join the virtual meeting
              room, click the button below. You can participate using a
              computer, tablet, or phone.
            </p>
          </div>
          <div className='bottom-container'>
            <h1>Join via Zoom App</h1>
            <p>
              To join using the Zoom app on your computer or mobile device,
              click the button below.
            </p>
            <button onClick={() => setShowMeetingForm(true)}>
              <VideocamIcon className='videocamIcon' />
              Join Zoom Meeting
            </button>
            <p className='password-required'>Password Required</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Meeting
