import React from 'react'
import './footer.css'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

export default function Footer () {
  return (
    <div className='footer'>
      <div
        class='fb-like'
        data-href='https://www.facebook.com/nolmhousechurch'
        data-width=''
        data-layout='button_count'
        data-action='like'
        data-size='large'
        data-share='false'
      ></div>
      <div className='social-icons'>
        <a
          href='https://www.facebook.com/nolmhousechurch'
          target='_blank'
          rel='noreferrer'
        >
          <FacebookIcon fontSize='large' />
        </a>
        <a
          href='https://twitter.com/JamesBa79831239'
          target='_blank'
          rel='noreferrer'
        >
          <TwitterIcon fontSize='large' />
        </a>
        <a
          href='https://www.linkedin.com/in/james-baker-sr-a4a2976a/'
          target='_blank'
          rel='noreferrer'
        >
          <LinkedInIcon fontSize='large' />
        </a>
      </div>
      <p>
        © 2021 NOLM House Church. All rights reserved.
        <a
          href='https://www.freeprivacypolicy.com/live/56b31602-cfcb-4806-bf65-536dd3b0d4a6'
          target='_blank'
          rel='noreferrer'
        >
          Privacy Policy
        </a>
        |
        <a
          href='https://www.termsfeed.com/live/375eae61-4a6a-4f03-b1a2-b87801c7b795'
          target='_blank'
          rel='noreferrer'
        >
          Terms of Use
        </a>
      </p>
    </div>
  )
}
