export const services = [
  {
    title: 'Office Administrator',
    description:
      'A church administrator ensures the smooth and efficient operation of a church through overseeing the daily activities and operations and managing the business side of the church. A church administrator is usually an active member of the church and sets an example for the congregation through his or her life and work. Responsibilities include:',
    moreDescription: [
      'Preparation and Production of weekly bulletin, monthly newsletter and Annual Report; Management of the Church office and records; Assistance with Financial Operations; Servings as in-person and telephone receptionist; Provide administrative support for Pastor, Church Staff and Session; Maintenance of the Church Calendar'
    ]
  },
  {
    title: 'Outreach Coordinator',
    description:
      'The Outreach Coordinator will design and produce materials, including but not limited to posters, social media posts, videos, and newsletters to promote, market and advertise outreach events, contacts church representatives, outreach organizations, discipleship programs, and community leaders to increase awareness of organizational mission, activities, and needs; recruit sponsors, participants, and volunteers for outreach and fundraising events; engages in social media outreach on a predetermined weekly basis with social media posts promoting the app minimally one time per week on the following platforms:  Facebook, Instagram. TikTok, Twitter, Snap Chat and LinkedIn; identifies and builds relationships with potential volunteers and donors; develops strategies to encourage new or increased contributions and creates and updates donor databases.'
  },
  {
    title: 'Marketing/Events Coordinator',
    description:
      'Is responsible for recruiting, building, and support an events ministry team; Acquire and organize event venues, speakers, music, presentation and sound equipment; liaison with event venues for event details:  power point, shipping of materials, etc.; oversee strategic marketing and communications for events; share connecting streams vision by networking with event participants; coordinate and facilitate post event evaluations; represent (host a table) the ministry at various strategic church and mission events'
  },
  {
    title: 'Music Director/Musicians',
    description:
      'The music director is in charge of leading the worship team on Sunday services as well as midweek services if applicable. She/he is also the one who leads worship team rehearsals and approves special music selections featuring soloists, choirs, or any other vocal or instrumental piece.  A church may have a choir director, but oftentimes the music director will serve as teacher and coordinator for the choir as well. The senior pastor will sometimes input into music selection, and the music director is often expected to work closely with the pastor.'
  },
  {
    title: 'Worship Team',
    description:
      'Vocalists and musicians who help engage the congregation in our weekly worship experience. Got what it takes? Become a part of the team!'
  },
  {
    title: 'Youth Director/teachers',
    description:
      'The responsibility of the director will be to prayerfully develop and coordinate quarterly curriculums recruit potential youth ministry teachers for each youth group, and coordinate rotation schedules.  A NOLM application and a criminal background check may be required for potential teachers to insure the safe environment for the families of our youth.  The senior pastor may also have some input on these matters.'
  },
  {
    title: 'Facility Team',
    description:
      'Roll up your sleeves and get a little dirty. Help the Facilities team maintain the upkeep of our church.'
  },
  {
    title: 'Small Group Leader',
    description:
      'It’s not about being perfect, it’s about bringing people together by giving them a place to connect. Leading a Group is simple: sign-up, attend training and we’ll give you the essential tools to make your Group successful.'
  },
  {
    title: 'Grant Writer',
    description:
      'The Grant Writer is a part-time position who will secure funds for the organization by identifying and developing grant opportunities; preparing grant proposals, applications, and reports as applicable, and management of applicable deadlines and processes.'
  },
  {
    title: 'Security',
    description:
      'Do you have a security background? Interested in helping with our Safety and Security Team? We would love to talk with you and let you know about several opportunities'
  },
  {
    title: 'Food Pantry Coordinator/Director',
    description:
      'Oversee the monthly operations of the NOLM Food Pantry. Position requires frequent contact with volunteers, vendors, clients, and the public.'
  },
  {
    title: 'Intercessory Prayer Team Coordinator',
    description:
      'The Prayer Team leader and will communicate prayer needs on a regular basis to the team members. NOLM’s prayer team will partner with outside prayer ministries teams as well which will be vital to the success of this ministry.'
  },
  {
    title: 'Usher/Parking Lot Team Coordinator',
    description:
      'The leader’s responsibility of the Ushers/parking lot attendants will be to help prepare the Worship Room, secure the premises, assist people in finding a parking spot and a seat once in-side, as well as collecting the tithes and offerings.  A church usher and parking lot attendant plays a large role in creating an atmosphere of welcoming and acceptance at a church.   It is his/her job to make them feel welcome and comfortable upon arrival on the church premises and/or when selecting a place to be seated in our worship center.'
  },
  {
    title: 'Media Team Members',
    description:
      "The media ministry team has a wide variety of responsibilities. During worship services or other large events, they run the sound board for the worship band and speakers, including the pastor. If PowerPoint or other media are used, the media specialist sets up all of the necessary equipment and ensures that it will run smoothly. The media specialists will tape and distribute audio or video recordings of the sermon; create a live stream on the church's website and updates the entire church website."
  },
  {
    title: 'Frontline Ministry Team Members',
    description:
      'It is the leader’s responsibility to ensure that the frontline team has the proper guidance and the materials they need to properly assist the people that are coming in.  The team plays a large role in creating an atmosphere of welcoming and acceptance at a church. He/she is often the first person that people meet as they walk through the front doors, and it is his/her job to make them feel welcome and comfortable on a number of levels. The frontline Team consists of:',
    moreDescription: [
      'Greeters: Always present a genuine pleasant smile and a warm greeting.',
      'Hospitality: Always serve with a pleasant smile and keep refreshments and beverages readily available.  Never run out.',
      'Information Center:  Be courteous and knowledgeable of ministry information and upcoming events to provide helpful information that will help others connect with NOLM ministry.'
    ]
  },
  {
    title: 'Volunteer Coordinator',
    description:
      'The Volunteer Coordinator provides comprehensive volunteer opportunities for churches, businesses, and community partners in support of the organization’s vision, mission, values, strategic plan, goals, and development philosophy.'
  }
]
