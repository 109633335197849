import JayImage from '../assets/images/jay.png'
import JamesImage from '../assets/images/james.jpg'
import LatashaImage from '../assets/images/latasha.png'
import JonathanImage from '../assets/images/Jonathan.jpg'

export const teamInfos = [
  {
    name: 'James Baker, Sr.',
    title: 'Founder, Lead Pastor',
    image: JamesImage,
    bio:
      'James is an alumnus of Belhaven University with a Bachelor of Arts degree in Biblical Studies. As Lead Pastor, he provides biblical vision and direction for the congregation, define, and communicate the church’s purpose, serves as church plant team leader, and entrepreneurial ownership of the ministry. This includes the following general aspects: Spiritual pulse of ministry, promote teamwork which includes weekly-on Sundays-contact with team leaders and members to encourage them.'
  },
  {
    name: 'LaTasha L. Baker',
    title: 'Co-Founder, Church Evangelist',
    image: LatashaImage,
    bio:
      'LaTasha is an alumnus of Southeastern Louisiana University with a Bachelor of Arts degree in Communications. As an office administrator, she ensures the smooth and efficient operation of a church through overseeing the daily activities and operations and managing the business side of the church. LaTasha prepares and produces weekly bulletins, monthly newsletters, annual reports, management of the church office and records, assistance with financial operations, serving as in-person and telephone receptionist, provide administrative support for the pastor and church staff.'
  },
  {
    name: 'Jay B. Crisostomo',
    title: 'Web Designer and Manager',
    image: JayImage,
    bio:
      'Jay is an alumnus of Louisiana State University with a Bachelor of Science degree in Computer Engineering.  He is a 21-year-old web developer who loves what he does.  At the age of 11 years old, he immigrated from the Philippines to the United States.  As a website developer and manager, he organizes NOLM’s church website, oversees the functionality of the website, evaluates, manages website performance, facilitate hosting and server management, develop, maintain, and update website content.'
  }
]
