import React, { useState } from 'react'
import './joinourteam.css'
import image4 from '../assets/images/image4.jpg'
import { data } from './joinourteamquestions'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { services } from './services.js'

function JoinOurTeam () {
  const [serviceClicked, setService] = useState('')

  return (
    <div className='join-our-team'>
      <div className='join-our-team-container'>
        <div className='image-join-our-team'>
          <img src={image4} alt='' />
        </div>
        <p style={{ fontWeight: 'bold' }}>
          No person can plant a church alone. It takes a team of diverse yet
          like-minded people united by a common vision. If you would like to
          join our serve team, start by asking yourself a few simple questions:
        </p>
        <br />
        {data.map((each, index) => (
          <p className='join-our-team-container-question'>
            <ChevronRightIcon />
            {each.question}
          </p>
        ))}
        <h1>WHAT IS THE SERVE TEAM?</h1>
        <p>
          Specifically, our serve teams are the volunteers that serve alongside
          our lead pastor in fulfilling the church’s mission and vision.
        </p>
        <h1>WHO CAN BE A MEMBER OF THE SERVE TEAM?</h1>
        <p>
          Ideally, serve team members are authentic Christ followers.
          Nevertheless, no special qualifications are needed to join our serve
          team except one must:
        </p>
        <div className='list-one'>
          <p>
            <ChevronRightIcon />
            Love and want to help people
          </p>
          <p>
            <ChevronRightIcon />
            Agree with our church vision
          </p>
          <p>
            <ChevronRightIcon />
            Be willing to serve
          </p>
          <p>
            <ChevronRightIcon />
            Be able to volunteer time weekly
          </p>
        </div>
        <h1>WHAT AREAS WILL THE DREAM TEAM MEMBERS SERVE?</h1>
        <p>
          Initially, serve team members will simply help with the ministry
          operations of the church. See the following serve positions:
        </p>
        <div className='list-zero'>
          {services.map((service, index) => (
            <div className='service-list' key={index}>
              <h2 className='service' onClick={() => setService(service.title)}>
                {service.title}
              </h2>
              {serviceClicked === service.title && service.description !== '' && (
                <div>
                  <p className='service-description'>{service.description}</p>
                  {service?.moreDescription &&
                    service?.moreDescription.map((desc, index) => (
                      <p className='more-description' key={index}>
                        <ChevronRightIcon />
                        {desc}
                      </p>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <h1>ARE SERVE TEAM MEMBERS PAID FOR THEIR SERVICES?</h1>
        <p>
          No, serve team members volunteer their service. Eventually, some serve
          team members may become paid staffers as the church grows.
        </p>
        <h1>WHAT TYPE OF COMMITMENT IS NEEDED?</h1>
        <p>
          To become a serve team member, we ask that you volunteer a few hours
          weekly for the following purposes:
        </p>
        <div className='list-one'>
          <p>
            <ChevronRightIcon />
            Attend monthly serve team meetings
          </p>
          <p>
            <ChevronRightIcon />
            Preferably attend weekly Bible study (in person or via zoom)
            <ArrowRightAltIcon />
            <a href='/meeting'>Click Here</a>
          </p>
        </div>
        <h1>WHAT ARE THE BENEFITS OF BECOMING A SERVE TEAM MEMBER?</h1>
        <p>
          We truly desire that each person who joins our serve team does so with
          prayerful consideration, and therefore, a benefit of joining is the
          satisfaction of knowing you truly are helping people and advancing
          God’s Kingdom. Another great benefit is the lifetime connections and
          friendships you will gain with some amazing individuals.
        </p>
        <h1>HOW TO BECOME A SERVE TEAM MEMBER?</h1>
        <p>
          To join our team, simply contact our office at (225) 397-9343, or
          complete the form below. We look forward to hearing from you.
        </p>
        <a href='/connection-card'>Connection Form</a>
      </div>
    </div>
  )
}
export default JoinOurTeam
