import React from 'react'
import './connect.css'
import image5 from '../assets/images/image5.jpg'
function Connect () {
  return (
    <div className='connect'>
      <div className='connect-container'>
        <img src={image5} alt='' />
        <h1>What to Expect at Newness of Life Ministries?</h1>
        <p>
          Expect a welcoming atmosphere and relevant life-giving messages in a
          family friendly environment.
        </p>
      </div>
    </div>
  )
}

export default Connect
