import React from 'react'
import './contact.css'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import PinDropIcon from '@mui/icons-material/PinDrop'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

function Contact () {
  return (
    <div className='contact'>
      <div className='contact-container' data-aos='zoom-in'>
        <h1>Contact</h1>
        <div className='contact-name'>
          <div className='contact-name-container'>
            <h2 className='contact-key'>
              <EmailIcon className='contact-icon' /> Email:
            </h2>
            <h2 className='contact-value'>nolmhousechurch2022@gmail.com</h2>
          </div>
          <div className='contact-name-container'>
            <h2 className='contact-key'>
              <LocalPhoneIcon className='contact-icon' /> Phone Number:
            </h2>
            <h2 className='contact-value'>225-397-9343</h2>
          </div>
          <div className='contact-name-container'>
            <h2 className='contact-key'>
              <MiscellaneousServicesIcon className='contact-icon' /> Weekend
              Services:
            </h2>
            <h2 className='contact-value'>
              Sundays, Small Group Bible Study, 10:00 AM
            </h2>
          </div>
          <div className='contact-name-container'>
            <h2 className='contact-key'>
              <PinDropIcon className='contact-icon' /> Church Meeting Address:
            </h2>
            <h2 className='contact-value'>TBA location</h2>
          </div>
          <div className='contact-name-container'>
            <h2 className='contact-key'>
              <MarkunreadMailboxIcon className='contact-icon' /> Church Mailing
              Address:
            </h2>
            <h2 className='contact-value'>
              P.O. Box 40892 Baton Rouge, LA 70835
            </h2>
          </div>
          <div className='contact-name-container'>
            <h2 className='contact-key'>
              <AccessTimeIcon className='contact-icon' /> Service Times:
            </h2>
            <h2 className='contact-value'>
              Sunday Mornings 10:00 am Wednesday Evenings 7:00 pm
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
