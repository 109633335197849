import React from "react";
import "./whywegive.css";

function WhyWeGive() {
  return (
    <div className="whywegive">
      <h2>
        Thank you so much for your generosity. Your contribution allows us to be
        the hands and feet of Christ, allows for growth and impact in the
        community and to the end of the Earth.
      </h2>
      <ul>
        <li>• Giving is an act of worship (2 Corinthians 9:12).</li>
        <li>• Giving tells the truth that God owns everything (Psalm 24:1).</li>
        <li>
          • Giving should be a heartfelt response to the grace of God (2
          Corinthians 9:7-8).
        </li>
        <li>• Giving helps build heavenly treasures (Matthew 6:19-24).</li>
        <li>
          • Giving demonstrates trust in God and an investment in eternity (1
          Timothy 6:17-19).
        </li>
        <li>• Giving follows Jesus’ example (2 Corinthians 8:8-9).</li>
      </ul>
    </div>
  );
}

export default WhyWeGive;
