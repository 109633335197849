export const data = [
  {
    name: 'THE HOLY BIBLE',
    description:
      'This Church accepts the Bible as the revealed inspired, inerrant, and only infallible, authoritative Word of God.  (2 Timothy 3:16; Proverbs 30:5; Romans 16:25-26)'
  },
  {
    name: 'THE ONE TRUE GOD',
    description:
      'The one true God has revealed Himself as eternally existent in three persons: Father, Son, and Holy Spirit.  These three are co-equal and co-eternal (1 John 5:7; Matthew 3:16-17, 28:19)'
  },
  {
    name: 'THE DEITY OF JESUS',
    description:
      'The Lord Jesus Christ is the eternal Son of God and the second person in the Trinity.  The Scriptures declare His virgin birth, sinless life, miracles, death, resurrection, and ascension to the right hand of God.  On earth He was 100 percent man and 100 percent God.  He is the only man ever to have lived a sinless life. (Matthew 1:18; Luke 1:35; John 1:1, 14; I Timothy 3:16; Isaiah 7:14; Isaiah 9:6; Philippians 2:5-6; I Timothy 2:5). '
  },
  {
    name: 'THE SALVATION OF MAN',
    description:
      'Man’s only hope is through the shed blood of Jesus Christ.  Salvation is by faith through grace and demonstrated in repentance. (Ephesians 2:8, 9; Galatians 2:16, 3:8; Titus 3:5; Romans 10:9-13; Acts 16:31; Hebrews 9:22).'
  },
  {
    name: 'REDEMPTION',
    description:
      'Man was created good and upright, but by voluntary transgression he fell; his only hope of redemption is in Jesus Christ, the Son of God (Genesis 1:26-31, 3:1-7; Romans 5:12-21).'
  },
  {
    name: 'REPENTANCE',
    description:
      'Repentance is the commitment to turn away from sin in every area of our lives and to follow Christ, which allows us to receive His redemption and to be generated by the Holy Spirit.  Thus, through repentance receive forgiveness of sins and appropriate salvation.  (Act 2:21. 3:19; I John 1:9)'
  },
  {
    name: 'JESUS’ BLOOD',
    description:
      'The blood of Jesus Christ shed on the Cross of Calvary was sinless and is 100% sufficient to cleanse mankind of all sin. Jesus allowed Himself to be punished for both our sinfulness and our sins, enabling all those who believe to be free from the penalty of sin, which is death (I John 1:7; Revelation 1:5; 5:9; Colossians 1:20; Romans 5:9).'
  },
  {
    name: 'RESURRECTION',
    description:
      'Jesus Christ was physically resurrected from the dead in a glorified body three days after His death on the cross. In addition, both the saved and the lost will be resurrected; they that are saved to the resurrection of life and they that are lost to the resurrection of eternal damnation (John 2:19-22; 20:26-29; 21:4; Acts 24:15; I Corinthians 15:42, 44).'
  },
  {
    name: 'HEAVEN',
    description:
      'Heaven is the eternal dwelling place for all believers in the Gospel of Jesus Christ (Matthew 5:3, 12, 20; 6:20; 19:21; 25:34; John 17:24; II Corinthians 5:1).'
  },
  {
    name: 'HELL',
    description:
      'After living one life on earth, the unbelievers will be judged by God and sent to Hell where they will be eternally tormented with Devil and the Fallen Angels (Matthews 25:41; Mark 9:43-48; Hebrews 9:27; Revelations 14:9-11; 20:12-15; 21:8).'
  },
  {
    name: 'SECOND COMING',
    description:
      'Jesus Christ will physically and visibly return to earth for the second time to establish His Kingdom.  This will occur at a date undisclosed by the Scriptures (Matthews 24:30; 26:63-64; Acts 1:9-11; I Thessalonians 4:15-17; II Thessalonians 1:7-8; Revelation 1:7).'
  },
  {
    name: 'ORDINANCES OF THE CHURCH',
    description: [
      'Water Baptism: Following faith in the Lord Jesus Christ, the new convert is commanded by the Word of God to be baptized in water in the name of the Father, Son, and Holy Ghost and by full immersion. (Matthew 3:13-17; Matthew 28:18-20; Acts 2:38; Acts 8:12, 36-38)',
      'Holy Communion: The Lord’s Supper consisting of the Body and Blood of Jesus Christ is represented in the fruit of the vine and bread, is a unique time of communion in the presence of God, taken in remembrance of Jesus’ sacrifice on the Cross (Matthew 26:26-30; 1 Corinthians 11:23-26).'
    ]
  },
  {
    name: 'THE BAPTISM OF THE HOLY SPIRIT',
    description:
      'According to Acts 1:8 and Acts 2:4, with one of the signs being speaking in other tongues along with other gifts. It was given at Pentecost, as promise of the Father. It was sent by Jesus after His Ascension, to empower the church to preach the Gospel throughout the whole earth (Joel 2:28-29; Acts 1:5, 2:1-4, 16-21, 38-39, 8:14-17, 10:38, 44-47, 11:15-17, 19:1-6).'
  },
  {
    name: 'SANCTIFICATION',
    description:
      'The act of separation from that which is evil, and dedication unto God. Sanctification is the ongoing process of yielding to God’s Word and His Spirit to complete the development of Christ’s character in us. It is through the present ministry of the Holy Spirit and the Word of God that the Christian is enabled to live a godly life (1Thessalonians 4:3-5, 5:23; 2 Corinthians 3:18, 6:14-18, Romans 8:29, 12:1-2)'
  }
]
