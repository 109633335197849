import React from 'react'
import './outreach.css'
import OutreachBanner from '../assets/images/outreach-banner.jpg'
function Outreach () {
  return (
    <div className='outreach'>
      <div className='outreach-container'>
        <img src={OutreachBanner} alt="" />
        <p>
          “For even the Son of Man came not to be served but to serve others and
          to give his life as a ransom for many.” Matthew 20:28 NLT
        </p>
      </div>
    </div>
  )
}

export default Outreach
