import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DescriptionIcon from "@mui/icons-material/Description";
import "./event.css";

function Event({
  title,
  date,
  time,
  description,
  isAdminLoggedIn,
  handleDeleteEvent,
  handleEdit,
}) {
  return (
    <div
      className="event-component"
      style={{
        marginTop: "10px",
        borderRadius: "10px",
        padding: "10px",
        color: "black",
        backgroundColor: "whitesmoke",
        position: "relative",
      }}
    >
      {isAdminLoggedIn && (
        <div
          className="edit-delete-buttons"
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            top: "5px",
            right: "5px",
          }}
        >
          <div onClick={handleEdit} style={{ cursor: "pointer" }}>
            <EditIcon />
          </div>
          <div onClick={handleDeleteEvent} style={{ cursor: "pointer" }}>
            <DeleteIcon />
          </div>
        </div>
      )}
      <h1>{title ? title : "No Title"}</h1>
      <h2>
        <EventIcon />
        {date}
      </h2>
      <h2>
        <AccessTimeIcon />
        {time}
      </h2>
      <p>
        <DescriptionIcon />
        {description}
      </p>
    </div>
  );
}

export default Event;
