import React from 'react'
import './nolmprayerteam.css'
import image from '../assets/images/PrayerTeam.png'

function NOLMPrayerTeam() {
  return (
    <div className="NOLMPrayerTeam">
        <img src={image} alt='' />
        <div className="team">
            <p><strong>Pastor James Baker:&nbsp;</strong> Prayer Team Leader</p>
            <p><strong>Evangelist LaTasha L. Baker:&nbsp;</strong> Prayer Team Member</p>
            <p><strong>Elder Mary August:&nbsp;</strong>  Prayer Team Member</p>
            <p><strong>Sister Monica Baker:&nbsp;</strong> Prayer Team Member</p>
        </div>
    </div>
  )
}

export default NOLMPrayerTeam