import React, { useState, useRef } from "react";
import "./prayerRequest.css";
import emailjs from "emailjs-com";

function PrayerRequest() {
  const [sendStatus, setSendStatus] = useState(false);
  const [error, setError] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dmk1yeo",
        "template_lhqbv9d",
        form.current,
        "pnE1sqFpLY_9pmmR3"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSendStatus(true);
        },
        (error) => {
          console.log(error.text);
          setError(true);
        }
      );
  };

  return (
    <div className="prayer_request">
      <div className="pr-top-container">
        <h1>Prayer Request</h1>
      </div>
      <div className="submission-container">
        <h2 className="h2-element">I have a prayer request</h2>
        <p className="p-element">
          If your have a prayer request or need that you would like help with,
          please be sure to contact us though our contact page and choose the
          topic “I have a prayer request”. All prayers and needs are posted
          anonymously unless otherwise specified. Prayer request are added on
          this page for our community to join in prayer for your request or help
          fill the need if possible.
        </p>
        <h2 className="h2-element">I want to help with a prayer request</h2>
        <p className="p-element">
          If you would like to help fill a prayer request on this page, please
          be sure to contact us though our contact page and choose the topic“I
          want to help with a prayer request” with the request number in the
          message area. Each prayer request will have a request number assigned
          at the top in bold letters.
        </p>
        <form ref={form} onSubmit={sendEmail} style={{ marginTop: "20px" }}>
          <div className="full-name">
            <label>Full Name</label>
            <input type="text" name="full_name" />
          </div>
          <div className="email">
            <label>Email</label>
            <input type="email" name="email" />
          </div>
          <div className="phone-number">
            <label>Phone Number</label>
            <input type="number" name="phone_number" />
          </div>
          <div className="prayer_request_container">
            <label>Prayer Request</label>
            <select name="prayer_request" id="prayer_request">
              <option value="I have a prayer Request">
                I have a prayer Request
              </option>
              <option value="I want to help with a prayer request">
                I want to help with a prayer request
              </option>
            </select>
          </div>
          <div className="message_3">
            <label>Message</label>
            <textarea name="message_3" />
          </div>
          <input type="submit" value="Send" className="send-button" />
          {sendStatus ? (
            <h3 className="success-sent">Sent!</h3>
          ) : error ? (
            <h3 className="error-sent">Error! Please contact us!</h3>
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default PrayerRequest;
