import React from 'react'
import './newtonolm.css'
import { data } from './newtonolmdata'

function NewToNOLM () {
  return (
    <div className='newtonolm'>
      <div className='newtonolm-container'>
        <h1>New to Newness of Life Ministries?</h1>
        <div className='blocks-container'>
          {data.map((each, index) => (
            <div className='block' key={index}>
              <h1>{each.question}</h1>
              <p>{each.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NewToNOLM
