import React from 'react'
import './media.css'

function Media () {
  return (
    <div className='media'>
      <div className='top-image'>
        <h1>Media</h1>
      </div>
      <div className="media-container">
        <h1>Messages & Full Services</h1>
        <div className="media-top-container">
        </div>
        <div className="media-bot-container"></div>
      </div>
    </div>
  )
}

export default Media
