import React, { useState, useEffect } from 'react'
import './about.css'
import JamesAndLaTasha from '../assets/images/JamesAndLaTasha.jpg'
import { data } from './whatwebeleivedata'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import WhoWeAre from './WhoWeAre'
import Contact from './Contact'
import AOS from 'aos'
import 'aos/dist/aos.css'

function About () {
  const [showMessage, setShowMessage] = useState('')
  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  }, [])
  return (
    <div className='about'>
      <div className='body_content'>
        <div id='who_we_are'>
          <WhoWeAre />
        </div>
        <div className='leadership' id='leadership'>
          <h1 data-aos='fade-left'>Leadership</h1>
          <img
            data-aos='fade-left'
            src={JamesAndLaTasha}
            alt='JamesAndLaTasha'
          />
          <div className='about_background'>
            <h2 data-aos='fade-right'>
              James Baker and LaTasha Baker, Founders
            </h2>
            <p data-aos='fade-right'>
            Elder James Baker, Sr. is a native of Independence, Louisiana and the youngest of nine siblings
            of Clarence and Ophelia Baker. He studied at Honolulu Community College, Central Texas
            Community College, Wesley Biblical Seminary, Jackson State University and Belhaven
            University, from which he earned a bachelor’s degree in biblical studies. He also received
            training as a Healthcare Specialist and Emergency Medical Technician at the Academy of
            Health and Science at Fort Sam Houston, San Antonio Texas and retired from the U.S. Army on
            June 15, 2005.
            <br/>
            <br/>
            Pastor Baker and his beautiful wife, LaTasha have been married for 30 years and have five
            sons, one daughter, and beautiful grandchildren. LaTasha is a native of McComb, Mississippi.
            She is a graduate of Southeastern Louisiana University with a Bachelor’s degree in
            Communications. She is an evangelist, book author, and motivational speaker.
            Elder Baker was called to ministry in 1996. In November 1997, he received his ministerial
            license and was ordained as an elder in February 2009. Further following the call of GOD upon
            his life, he accepted the call to the office of pastoral care and in February 2013. Through his
            home church, The Mission Church, Hammond, Louisiana, he was ordained and installed as the
            Lead Pastor of Newness of Life Ministries (NOLM), a new church plant, in his hometown of
            Independence, Louisiana.
            <br/>
            <br/>
            In the next steps of this church plant journey, Pastor Baker is listening to what God is saying
            about the city of Baton Rouge and how he can partner with other organizations to meet the
            needs of the people through community outreach in East Baton Rouge Parish.
            <br/>
            <br/>
            If you could sum up Pastor Baker’s’ heart and passion in ministry, it would be “servant and
            visionary leader”. He challenges the people of Newness of Life Ministries to develop deep
            relationships for the purpose of loving God and loving others. He is a man of God whose
            spiritual DNA is missions and evangelism. As he lives out his destiny doing God’s will, he will
            share the gospel and the love of Jesus Christ while building Christian communities, one family
            at a time.
            </p>
          </div>
        </div>
        <div className='purposeandpassion' id='purposeandpassion'>
          <div class='purpose-container' data-aos='fade-left'>
            <h1>Purpose and Passion</h1>
            <h3>
              NOLM is organized solely and exclusively for religious,
              charitable, and educational purposes. Our godly aspiration is to
              build and strengthen our communities, one family at a time. We are{' '}
              <span className='highlight'>committed</span> to leading people to
              Christ and equipping them to evangelize the world by sharing the
              good news of the gospel of Jesus. <br />
              <br />
              NOLM has a <span className='highlight'>passion to love God</span>,
              serve people, and change lives. We desire to inspire the
              “physical” as well as the “spiritual progress” and general welfare
              of our communities. We will be securing, developing, and sharing
              available resources and services empowering citizens to become a
              vibrant community by going beyond charity. Paul says, “My purpose
              is that they may be encouraged in heart and united in love…”
              Colossians 2:2. Come join us on this journey!
            </h3>
          </div>
        </div>
        <div className='whatwebeleive-container' id='whatwebeleive'>
          <div className='whatwebeleive' data-aos='fade-right'>
            <h1>What We Believe</h1>
            {data.map((each, index) => (
              <div className='message' key={index}>
                <div
                  className='what-we-beleive-option'
                  onClick={() => {
                    if (each.name === showMessage) {
                      setShowMessage('')
                    } else {
                      setShowMessage(each.name)
                    }
                  }}
                >
                  <div className='plus-minus-icon'>
                    {each.name === showMessage ? (
                      <RemoveIcon
                        sx={{
                          height: '42px',
                          backgroundColor: 'rgb(255, 124, 174)'
                        }}
                        fontSize='large'
                      />
                    ) : (
                      <AddIcon sx={{ height: '42px' }} fontSize='large' />
                    )}
                  </div>
                  <h1>{each.name}</h1>
                </div>
                {each.name === showMessage ? (
                  <p className='what-we-beleive-description'>
                    {Array.isArray(each.description)
                      ? each.description.map(desc => <p>{desc}</p>)
                      : each.description}
                  </p>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <div id='contact_about'>
          <Contact />
        </div>
      </div>
    </div>
  )
}

export default About
