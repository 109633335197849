import React, { useState, useRef } from 'react'
import './connectionCard.css'
import emailjs from 'emailjs-com'
import ConnectionHeader from '../assets/images/image3.jpg'

function ConnectionCard () {
  const [sendStatus, setSendStatus] = useState(false)
  const [error, setError] = useState(false)

  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_dmk1yeo',
        'template_8783lov',
        form.current,
        'pnE1sqFpLY_9pmmR3'
      )
      .then(
        result => {
          console.log(result.text)
          setSendStatus(true)
        },
        error => {
          console.log(error.text)
          setError(true)
        }
      )
  }

  return (
    <div className='connection-card'>
      <img src={ConnectionHeader} alt='' />
      <div className='connection-card-container'>
        <h1>
          Welcome to Newness of Life Ministries (NOLM). We’re glad you’re here.
          Our team would love to serve you and help you get connected.
        </h1>
        <div className='submission-container'>
          <form ref={form} onSubmit={sendEmail}>
            <div className='full-name'>
              <label>First Name</label>
              <input type='text' name='first_name' />
              <label>Last Name</label>
              <input type='text' name='last_name' />
            </div>
            <div className='gender'>
              <label>Gender</label>
              <select name='gender' id='gender'>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
                <option value='Other'>Other</option>
              </select>
            </div>
            <div className='status'>
              <label>Status</label>
              <select name='status' id='status'>
                <option value='Single'>Single</option>
                <option value='Married'>Married</option>
                <option value='Other'>Other</option>
              </select>
            </div>
            <div className='email-phone-address'>
              <label>Email</label>
              <input type='email' name='email' />
              <label>Phone Number</label>
              <input type='number' name='phone_number' />
              <label>Address 1</label>
              <input type='text' name='address_one' />
              <label>Address 2</label>
              <input type='text' name='address_two' />
            </div>
            <div className='country-city-state-zip'>
              <label>Country</label>
              <input type='text' name='country' />
              <label>City</label>
              <input type='text' name='city' />
              <label>State</label>
              <input type='text' name='state' />
              <label>Zip/Postal Code</label>
              <input type='number' name='zip' />
            </div>
            <div className='decision'>
              <label>My Decision today</label>
              <div className='decision-option'>
                <input
                  type='checkbox'
                  id='option_1'
                  name='option_1'
                  value="I'm committing (or renewing) my life to Christ."
                />
                <label>I'm committing (or renewing) my life to Christ.</label>
              </div>
              <div className='decision-option'>
                <input
                  type='checkbox'
                  id='option_1'
                  name='option_2'
                  value="I'd like to be baptized."
                />
                <label>I'd like to be baptized.</label>
              </div>
              <div className='decision-option'>
                <input
                  type='checkbox'
                  id='option_3'
                  name='option_3'
                  value='I want to join NOLM Church.'
                />
                <label>I want to join NOLM Church.</label>
              </div>
              <div className='decision-option'>
                <input
                  type='checkbox'
                  id='option_4'
                  name='option_4'
                  value="I'd like to talk with a ministry leader."
                />
                <label>I'd like to talk with a ministry leader.</label>
              </div>
            </div>
            <div className='tell-me-more'>
              <label>Tell me more about:</label>
              <select name='tell_me_more' id='tell_me_more'>
                <option value='How to connect'>How to connect</option>
                <option value='How to grow'>How to grow</option>
                <option value='How to serve'>How to serve</option>
                <option value='How to join NOLM Church'>
                  How to join NOLM Church
                </option>
              </select>
            </div>
            <div className='how-did-you-hear-about-us'>
              <label>How did you hear about us?</label>
              <textarea name='message' />
            </div>
            <div className='anything-you-like-to-share'>
              <label>Anything else you'd like to share?</label>
              <textarea name='message_2' />
            </div>
            <input type='submit' value='Send' className='send-button' />
            {sendStatus ? (
              <h3 className='success-sent'>Sent!</h3>
            ) : error ? (
              <h3 className='error-sent'>Error! Please contact us!</h3>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  )
}

export default ConnectionCard
