export const data = [
  {
    question:
      'Do I want to make a difference in the lives of others and do something that matters for Christ?'
  },
  {
    question: 'Do I sense God telling me to help serve with this ministry?'
  },
  {
    question: 'Do I want to help better my community?'
  },
  {
    question: 'Do I feel a connection with the church leadership?'
  },
  {
    question:
      'Do I agree with the vision, mission, and values of this ministry?'
  },
  {
    question:
      'If your answer is yes to any of these questions, please keep reading.'
  }
]
