import React from 'react'
import './whoweare.css'
import image12 from '../assets/images/image12.jpg'
import image10 from '../assets/images/image10.jpg'

function WhoWeAre () {
  return (
    <div className='whoweare'>
      <div
        className='about-new-nolm'
        data-aos='fade-up-right'
        data-aos-offset={0}
      >
        <h1>About Newness of Life Ministries</h1>
        <p>
          Newness of Life Ministries (NOLM) is a non-denominational, non-profit
          ministry that see God’s design for the church to be a Christ-led,
          growing, loving, life-giving force. We are called to East Baton and
          Tangipahoa Parishes to inspire people to walk in the newness of life
          by establishing a biblical foundation, equip believers to minister,
          empower disciples to make disciples of Christ, engage culture and
          community. In short, we are called to be the church. Currently, we are
          seeking and listening to what God is saying about our communities and
          gathering relevant information on how we can better serve His people.
          Please visit our site regularly to see updates and new content.
        </p>
      </div>
      <div className='our-vision' data-aos='fade-up-left' data-aos-offset={0}>
        <img src={image12} alt='' />
        <p>Building Christian Communities, One Family at a Time</p>
      </div>
      <div className='our-mission' data-aos='fade-up-right' data-aos-offset={0}>
        <img src={image10} alt='' />
        <p>
          Our mission is to fulfill the Great Commission by spreading the Gospel
          of Jesus Christ, through intentional discipleship training that will
          equip believers to share the good news and the love of Jesus Christ to
          everyone around the world. Jesus came and told his disciples, “I have
          been given all authority in heaven and on earth. Therefore, go and
          make disciples of all the nations, baptizing them in the name of the
          Father and the Son and the Holy Spirit. Teach these new disciples to
          obey all the commands I have given you. And be sure of this: I am with
          you always, even to the end of the age.” Matthew 28:18-20 NLT
        </p>
      </div>
    </div>
  )
}

export default WhoWeAre
