import React from 'react'
import './housing.css'
import image11 from '../assets/images/image11.jpg'

function Housing () {
  return (
    <div className='housing'>
      <div className='housing-container'>
        <div className='housing-img-container'>
          <img src={image11} alt='' />
        </div>
        <p className='housing-title'>NOLM Affordable Housing</p>
        <p>
          NOLM Affordable Housing is a non-profit community outreach program.
          Our mission is to provide safe and affordable housing to working
          families of low to moderate income levels in our communities. Rental
          housing of all types will be provided, both new construction and
          renovated properties for multi-family housing in urban and rural
          communities. We will always help people from all walks of life to
          include seniors, veterans, and persons with disabilities to help find
          a place that they can call home. We will partner with other housing
          agencies and programs to accomplish this mission. For more
          information, contact our office at (225) 397-9343.
        </p>
      </div>
    </div>
  )
}

export default Housing
