import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from './components/Homepage'
import Navigation from './components/Navigation'
import About from './components/About'
import Give from './components/Give'
import Events from './components/Events'
import Outreach from './components/Outreach'
import PrayerRequest from './components/PrayerRequest'
import ConnectionCard from './components/ConnectionCard'
import GrowGroups from './components/GrowGroups'
import Footer from './components/Footer'
import Media from './components/Media'
import Meeting from './components/Meeting'
import Connect from './components/Connect'
import NewToNOLM from './components/NewToNOLM'
import JoinOurTeam from './components/JoinOurTeam'
import FoodPantry from './components/FoodPantry'
import Housing from './components/Housing'
import Volunteer from './components/Volunteer'
import DreamTeam from './components/DreamTeam'
import WhyWeGive from './components/WhyWeGive'
import NOLMPrayerTeam from './components/NOLMPrayerTeam'

function App () {
  return (
    <Router>
      <div className='app'>
        <Navigation />
        <div className='content'>
          <Switch>
            <Route exact path='/'>
              <Homepage />
            </Route>
            <Route path='/about'>
              <About />
            </Route>
            <Route path='/outreach'>
              <Outreach />
            </Route>
            <Route path='/prayerrequest'>
              <PrayerRequest />
            </Route>
            <Route path='/connection-card'>
              <ConnectionCard />
            </Route>
            <Route path='/give'>
              <Give />
            </Route>
            <Route path='/grow-groups'>
              <GrowGroups />
            </Route>
            <Route path='/media'>
              <Media />
            </Route>
            <Route path='/events'>
              <Events />
            </Route>
            <Route path='/meeting'>
              <Meeting />
            </Route>
            <Route path='/connect'>
              <Connect />
            </Route>
            <Route path='/new-to-nolm'>
              <NewToNOLM />
            </Route>
            <Route path='/join-our-team'>
              <JoinOurTeam />
            </Route>
            <Route path='/food-pantry'>
              <FoodPantry />
            </Route>
            <Route path='/housing'>
              <Housing />
            </Route>
            <Route path='/volunteer'>
              <Volunteer />
            </Route>
            <Route path='/dream-team'>
              <DreamTeam />
            </Route>
            <Route path='/why-we-give'>
              <WhyWeGive />
            </Route>
            <Route path='/nolm-prayer-team'>
              <NOLMPrayerTeam />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default App
