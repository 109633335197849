import React from 'react'
import './foodpantry.css'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PinDropIcon from '@mui/icons-material/PinDrop'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PhoneIcon from '@mui/icons-material/Phone'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EmailIcon from '@mui/icons-material/Email'
import NOLMPdfFile from '../assets/files/NOLMFoodPantry.pdf'
import OutreachLogo from '../assets/images/outreachLogo.jpg'

function FoodPantry () {
  return (
    <div className='food-pantry'>
      <div className='food-pantry-container'>
        <div className='left-content'>
          <div className='container mg_bottom'>
            <div className='download'>
              <h1>NOLM Food Pantry</h1>
              <a href={NOLMPdfFile} download>
                Download
                <PictureAsPdfIcon />
              </a>
            </div>
            <div>
              <PinDropIcon fontSize='medium' />
              TBA
            </div>
            <div>
              <PhoneIcon fontSize='medium' />
              Office Phone #: 225-397-9343
            </div>
            <div>
              <CalendarTodayIcon fontSize='medium' />
              1st Saturday of every month
            </div>
            <div>
              <AccessTimeIcon />
              10:00 am - 12:00 pm
            </div>
          </div>
          <div className='container mg_bottom'>
            <h1>Application Information</h1>
            <span>*Contact Our Daily Bread*</span>
            <div>
              <EmailIcon fontSize='medium' />
              ourdailybreadhammond.org
            </div>
            <div>
              <PhoneIcon fontSize='medium' />
              (985)-542-4676{' '}
            </div>
            <div>
              <CalendarTodayIcon fontSize='medium' />
              Monday, Tuesday, and Thursday{' '}
            </div>
            <div>
              <AccessTimeIcon />
              8:00 am - 2:00 pm
            </div>
          </div>
          <div className='container'>
            <a
              href='https://goo.gl/maps/1WkBGchNbvixKqcu6'
              target='_blank'
              rel='noopener noreferrer'
            >
              Direction
            </a>
            <div>
              <span>START</span> Take Exit 40 towards La-40 W
            </div>
            <div>
              <span>1.7 mi</span> Turn onto HWY 40W (La-40 W)
            </div>
            <div>
              <span>1.5 mi</span> Turn right onto Hano Rd
            </div>
            <div>
              <span>0.8 mi</span> Turn left onto Mt. Temple Church Rd.
            </div>
            <div>
              <span>0.1 mi</span> Turn right
            </div>
          </div>
        </div>
        <div className='right-image'>
          <div className='right-img-container'>
            <img src={OutreachLogo} alt='' />
            <h2>Newness of Life Ministries' Food Pantry</h2>
            <p>
              More than 73.4% of residents considered to be low-income residents
              in Independence, LA, have annual household incomes at or beneath
              the poverty line. For one in eight Louisianans, hunger is a
              reality. Many people believe that the problems associated with
              hunger are confined to small pockets in a community, certain areas
              of our state, or even certain neighborhoods, but the reality is
              much different. Right now, thousands of men, women and children in
              south Louisiana are struggling with hunger. We all know and are in
              contact with people affected by hunger, even though we might not
              be aware of it. In our ongoing efforts to eliminate hunger, we
              have partnered with Our Daily Bread located in Hammond, LA.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FoodPantry
