import React from 'react'
import './team.css'
import PlaceHolder from '../assets/images/placeholder.jpeg'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

function Team ({ image, title, name, bio }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div className='team'>
      {image ? <img src={image} alt="team"/> : <img src={PlaceHolder} alt="team"/>}
      <h3>{name}</h3>
      <h4>{title}</h4>
      <button onClick={handleOpen}>
        Read Bio <ArrowRightAltIcon />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className='modal-team'>
          {image ? <img src={image} alt="team"/> : <img src={PlaceHolder} alt="team"/>}
          <h3>{name}</h3>
          <h4>{title}</h4>
          <p>{bio}</p>
        </Box>
      </Modal>
    </div>
  )
}

export default Team
