import React from 'react'
import './growGroups.css'
import VideocamIcon from '@mui/icons-material/Videocam'
import image9 from '../assets/images/image9.jpg'
import image7 from '../assets/images/image7.jpg'

export default function GrowGroups () {
  return (
    <div className='grow-groups'>
      <div className='grow-group-container'>
        <img src={image7} alt='' />
        <p style={{ marginBottom: 30 }}>
          Our desire is to see people connected to God through the good news of
          Jesus Christ. We’re also keen for people to build real connections
          with each other centered on fellowship with God. This starts as people
          come along to church and get involved in one of our weekly
          congregations. But it doesn’t stop here. Real connections are
          developed as people engage with each other in meaningful
          relationships. People grow as they learn from God’s word and apply it
          in their lives. Growth groups provide an important context for people
          to grow together in living out the implications of the gospel. As
          people grasp the gospel more and more, so they will be moved to serve
          God and others with the gifts God has given them.
        </p>
        <img src={image9} alt='' />
        <h1>NOLM Small Group Bible Study</h1>
        <p>
          Newness of Life Ministries’ (NOLM) small group Bible study sessions
          allow one to encounter the love of God through relevant life-giving
          topics for discussion in a comfortable environment and a safe space
          for real talk without judgement. Connect with us weekly via zoom and
          let’s talk!
        </p>
        <a href='/meeting'>
          <VideocamIcon className='video-icon' />
          Zoom Meeting
        </a>
      </div>
    </div>
  )
}
