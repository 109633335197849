import React, { useEffect } from 'react'
import './homepage.css'
import HomepageLogo from '../assets/videos/homepage-logo.webm'
import { useSpring, animated } from 'react-spring'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Homepage () {
  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  }, [])
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500
  })

  return (
    <div className='homepage'>
      <div className='homepage-top-container'>
        <video src={HomepageLogo} autoPlay={true} loop />
        <animated.div style={props} className='homepage_text'>
          <p>
            "Now all glory to God, who is able, through his mighty power at work
            within us, to accomplish infinitely more than we might ask or
            think." Ephesians 3:20
          </p>
          <a href='/about'>About Us</a>
        </animated.div>
      </div>
      <div className='home-container'>
        <div data-aos='fade-right' className='intro' data-aos-once={true}>
          <h1>
            DISCOVER YOUR PURPOSE AT <span>NEWNESS OF LIFE MINISTRIES </span>
          </h1>
          <p>
            Welcome to a place that has a house church environment that focus on
            family, fellowship, fun and faith. Visit, get connected, discover
            your passion and pursue your purpose. Become a part of something
            that is greater than yourself. Is your life running on empty? Fuel
            up free at Newness of Life Ministries (NOLM) every Sunday! Take the
            next steps towards a fresh start and a new life in Christ.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Homepage
