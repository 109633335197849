export const data = [
  {
    question: 'How can I attend?',
    answer:
      'Join us via Zoom on our weekly small group Bible Study session every Sunday morning at 10:00 am.  Click on the “zoom meeting” tab and get connected.'
  },
  {
    question: 'Can I attend if I’m ______ ?',
    answer:
      'Fill in the blank with whatever fear you have about not being accepted: divorced, single parent, broke, addict, gay, transgender, atheist, skeptic, etc. Everyone is welcome at Newness of Life. So come as you are.'
  },
  {
    question: 'Am I too far away from God?',
    answer:
      'At Newness of Life Ministries, we believe no one is too far away from God or unable to take one simple step toward Him. An extraordinary life in Jesus is available to everyone if they are only willing to take that one step. You will often hear us say, "God is one step away."'
  },
  {
    question: 'What should I wear?',
    answer:
      'Do not feel like you must dress up.   Most people wear casual attire, but you are welcome to wear whatever makes you comfortable but appropriate.'
  }
]
