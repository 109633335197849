import React from "react";
import "./navigation.css";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <div className={`navigation`}>
      <div className="navigation-tab">
        <Link to="/" className="link">
          Home
        </Link>
      </div>
      <div className="navigation-tab">
        <div class="dropdown">
          <Link to="/about" className="link">
            About Us
          </Link>
          <div class="dropdown-content">
            <a href="/about/#who_we_are">Who We Are</a>
            <a href="/about/#leadership">Leadership</a>
            <a href="/about/#purposeandpassion">Purpose and Passion</a>
            <a href="/about/#whatwebeleive">What We believe</a>
            <a href="/about/#contact_about">Contact</a>
          </div>
        </div>
      </div>
      <div className="navigation-tab">
        <div class="dropdown">
          <Link to="/connect" className="link">
            Connect
          </Link>
          <div class="dropdown-content">
            <a href="/new-to-nolm">New To NOLM?</a>
            <a href="/grow-groups">Grow Groups</a>
            <a href="/meeting">Zoom Meeting</a>
            <a href="/dream-team">NOLM Dream Team</a>
            <a href="/join-our-team">Join Our Team</a>
            <a href="/connection-card">Connection Card</a>
            <a href="/prayerrequest">Prayer Request</a>
            <a href="/nolm-prayer-team">NOLM Prayer Team</a>
          </div>
        </div>
      </div>
      <div className="navigation-tab">
        <div class="dropdown">
          <Link to="/give" className="link">
            Give
          </Link>
          <div class="dropdown-content">
            <a href="/why-we-give">Why We Give</a>
          </div>
        </div>
      </div>
      <div className="navigation-tab">
        <div class="dropdown">
          <Link to="/outreach" className="link">
            Outreach
          </Link>
          <div class="dropdown-content">
            <a href="/food-pantry">Food Pantry</a>
            <a href="/housing">Housing</a>
            <a href="/volunteer">Volunteer</a>
          </div>
        </div>
      </div>
      <div className="navigation-tab">
        <Link to="/events" className="link">
          Events
        </Link>
      </div>
    </div>
  );
}

export default Navigation;
